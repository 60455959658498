const styles = (theme) => ({
  contentBody: {
    fontSize: 16,
    color: '#333333',
    [theme.breakpoints.up('md')]: {
      fontSize: 20
    }
  },
  contentHead: {
    margin: '32px 0 16px',
    fontSize: 20,
    fontWeight: 500,
    color: '#333333',
    '&.smallHead': {
      fontSize: 16
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 24,
      '&.smallHead': {
        fontSize: 20
      }
    }
  },
  faqLink: {
    color: '#0071ce'
  },
  faqWrapper: {
    margin: '24px 14px 24px 18px',
    [theme.breakpoints.up('sm')]: {
      margin: '24px 56px'
    },
    [theme.breakpoints.up('md')]: {
      margin: '24px 75px'
    },
    [theme.breakpoints.up('lg')]: {
      margin: '24px 125px 18px'
    }
  },
  gridContainer: {
    maxWidth: '100vw',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    overflowX: 'hidden',
    marginTop: 64
  },
  header: {
    display: 'flex',
    width: '100vw'
  },
  headVideoWrapper: {
    minHeight: 90,
    '& div div div div': {
      paddingTop: '28% !important'
    },
    '& .video-js .vjs-dock-text': {
      display: 'none'
    },
    '& .bc-iframe, .bc-iframe body, .bc-player-default_default, .bc-player-default_default .vjs-poster':
      {
        backgroundColor: 'white'
      },
    [theme.breakpoints.up('sm')]: {
      width: '100vw',
      minHeight: 200
    },
    [theme.breakpoints.up('md')]: {
      minHeight: 280
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: 400
    }
  },
  landingWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  btnContainer: {
    display: 'flex',
    gap: 24,
    marginRight: 16,
    [theme.breakpoints.up('md')]: {
      marginRight: 28
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: 32
    }
  },
  headerBtn: {
    padding: '8px 24px',
    height: 40,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 700
  },
  joinWailistBtn: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  nav: {
    minWidth: '100vw',
    display: 'flex',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    height: 64,
    zIndex: 2,
    backgroundColor: '#ffffff',
    boxShadow: '0px 0px 10px 0px #0000001A',
    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
      justifyContent: 'space-between'
    }
  },
  waitlistContainer: {
    padding: '4rem 4rem 10rem 4rem',
    [theme.breakpoints.down('md')]: {
      padding: '3rem'
    },
    [theme.breakpoints.down('768')]: {
      padding: '6px 6px 3rem 6px'
    }
  },
  note: {
    fontWeight: 300,
    fontSize: 16,
    [theme.breakpoints.up('md')]: {
      fontSize: 20
    }
  },
  qsContainer: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      position: 'relative',
      flexWrap: 'nowrap'
    }
  },
  qsHead: {
    margin: '0 0 16px',
    fontSize: 20,
    fontWeight: 500,
    color: '#333333'
  },
  qsWrapper: {
    padding: '32px 16px',
    [theme.breakpoints.up('sm')]: {
      width: '50%',
      padding: '34px 50px'
    },
    [theme.breakpoints.up('md')]: {
      padding: '54px 73px'
    },
    [theme.breakpoints.up('lg')]: {
      padding: '164px 105px 164px 123px'
    }
  },
  seeMore: {
    fontSize: 14,
    fontWeight: 500,
    color: '#0071ce',
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  videoWrapper: {
    minHeight: 200,
    '& .bc-iframe, .bc-iframe body, .bc-player-default_default, .bc-player-default_default .vjs-poster':
      {
        backgroundColor: 'white'
      },
    [theme.breakpoints.up('sm')]: {
      width: '50%',
      alignSelf: 'center'
    },
    [theme.breakpoints.up('md')]: {
      width: '50%'
    }
  }
});

export default styles;
