import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import HomeFooter from '../../components/Footer/HomeFooter';
import styles from './styles.module.css';
import { Button } from '@walmart-web/livingdesign-components';

import WaitlistFeaturedSection from '../WaitlistFeaturedSection/index.tsx';

const Waitlist = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const success = params.get('success') === 'true';

  const PageHeader = () => (
    <header>
      <nav className={styles.nav}>
        <div className={styles.landingWrapper}>
          <div className={styles.logo}>
            <img src={'assets/images/white-logo.png'} alt={'Spark'} />
            <p>Customer Spark</p>
          </div>
          <Link to="login" className={styles.login}>
            Log in
          </Link>
        </div>
      </nav>
    </header>
  );

  if (success)
    return (
      <>
        <PageHeader />
        <div className={styles.confirmation}>
          <div className={styles.mainContainer}>
            <div className={styles.row}>
              <div className={`${styles.col12} ${styles.thankyou}`}>
                <div
                  className={styles.content}
                  data-testid="waitlist-confirmation"
                >
                  <img
                    src={'/assets/images/walmart-logo.png'}
                    alt={'Spark logo'}
                    height={100}
                    data-test-id="spark-logo"
                  />
                  <h2
                    className={`${styles.marginTop30}`}
                    data-testid="waitlist-confirmation-heading"
                  >
                    You’ve been added to our waitlist!
                  </h2>
                  <p
                    className={`${styles.marginTop30}`}
                    data-testid="waitlist-confirmation-body"
                  >
                    If you qualify, we will send you an invitation to your email
                    associated with your Walmart.com account.
                    <br />
                    Thank you for your interest in becoming a Walmart Customer
                    Spark Community Member.
                  </p>
                  <Button
                    size="small"
                    className={`${styles.marginTop30}`}
                    variant="primary"
                    isFullWidth={false}
                    href="/"
                    data-testid="waitlist-confirmation-btn"
                  >
                    Ok
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );

  return (
    <>
      <PageHeader />
      <div className={styles.mainContainer}>
        <WaitlistFeaturedSection useWailistSiteHeading />
        <div className={styles.paddingLeft10}>
          <h2>Who are we? How does it work?</h2>
        </div>
        <div className={styles.row}>
          <div className={`${styles.col} ${styles.sm12} ${styles.md6}`}>
            <div className={`${styles.row} ${styles.card}`}>
              <div className={`${styles.col3}`}>
                <img
                  className={styles.cardImg}
                  src={'/assets/images/waitlist/whoarewe.png'}
                  alt=""
                />
              </div>
              <div className={`${styles.col9}`}>
                <h3>Who are we?</h3>
                <p className={`${styles.marginTop10} ${styles.text}`}>
                  We are a community of carefully selected individuals who
                  represent the voice of Walmart shoppers. Once members join our
                  community, they immediately participate in activities & earn
                  rewards.
                </p>
              </div>
            </div>
          </div>
          <div className={`${styles.col} ${styles.sm12} ${styles.md6}`}>
            <div className={`${styles.row} ${styles.card}`}>
              <div className={`${styles.col3}`}>
                <img
                  className={styles.cardImg}
                  src={'/assets/images/waitlist/howdoeswork.png'}
                  alt=""
                />
              </div>
              <div className={`${styles.col9}`}>
                <h3>How does it work?</h3>
                <p className={`${styles.marginTop10} ${styles.text}`}>
                  Most activities take no longer than a few minutes to complete!
                  After each activity is completed, members instantly earn
                  points that can be used towards Walmart eGift cards!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.marginTop30} ${styles.paddingLeft10}`}>
          <h2>What is in it for me?</h2>
        </div>
        <div className={styles.row}>
          <div className={`${styles.col} ${styles.sm12} ${styles.md6}`}>
            <div className={`${styles.row} ${styles.card}`}>
              <div className={`${styles.col3}`}>
                <img
                  className={styles.cardImg}
                  src={'/assets/images/waitlist/reward.png'}
                  alt=""
                />
              </div>
              <div className={`${styles.col9}`}>
                <h3>Earn rewards for every completed activity</h3>
                <p className={`${styles.marginTop10} ${styles.text}`}>
                  As a member, you would have opportunities to provide your
                  feedback to earn points that you can redeem for Walmart eGift
                  cards.
                </p>
              </div>
            </div>
          </div>
          <div className={`${styles.col} ${styles.sm12} ${styles.md6}`}>
            <div className={`${styles.row} ${styles.card}`}>
              <div className={`${styles.col3}`}>
                <img
                  className={styles.cardImg}
                  src={'/assets/images/waitlist/impact.png'}
                  alt=""
                />
              </div>
              <div className={`${styles.col9}`}>
                <h3>Drive impact for the brands you love!</h3>
                <p className={`${styles.marginTop10} ${styles.text}`}>
                  Members voice their opinions about their favorite brands at
                  Walmart. As a member, your feedback could be used to make
                  decisions about product improvements.{' '}
                  <span className={styles.fwb}>Your voice matters!</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <HomeFooter />
      </div>
    </>
  );
};

export default Waitlist;
