const styles = (theme) => ({
  wrapper: {
    width: '100%',
    marginBottom: 20,
    zIndex: 2,
    marginTop: 140,
    color: '#3a3a3a',
    background: '#fff',
    borderTop: 'solid 1px #dadce0',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    paddingLeft: '50px',
    [theme.breakpoints.up('lg')]: {
      marginTop: 174
    }
  },
  link: {
    fontFamily: 'Bogle-Regular',
    fontWeight: 400,
    fontSize: '14px',
    margin: '10px 0 10px 0',
    [theme.breakpoints.up('sm')]: {
      //marginRight: 34
    },
    [theme.breakpoints.up('md')]: {
      //marginRight: 21
    }
  },
  linkWrapper: {
    flexWrap: 'nowrap',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: '32px 10px 24px',
    [theme.breakpoints.down('700')]: {
      flexDirection: 'column'
    },
    [theme.breakpoints.up('700')]: {
      width: '60%'
    },
    [theme.breakpoints.up('md')]: {
      width: '45%'
    },
    [theme.breakpoints.up('lg')]: {
      width: '35%'
    }
  },
  copyright: {
    textAlign: 'start',
    marginLeft: 5,
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.up('md')]: {
      //marginLeft: 270
    },
    [theme.breakpoints.up('lg')]: {
      //marginLeft: 470
    }
  },
  footerSubtitle: {
    fontFamily: 'Bogle-Regular',
    fontWeight: 700,
    fontSize: '16px'
  },
  privacyIcon: {
    marginLeft: 10,
    width: 'auto',
    height: '70%'
  }
});

export default styles;
