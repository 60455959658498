import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from '@walmart-web/livingdesign-components';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { useWindowSize } from '../../hooks';
import Carousel from '../../components/Carousel';
import HomeFooter from '../../components/Footer/HomeFooter';
import Logo from '../../components/Logo';
import VideoPlayer from '../../components/VideoPlayer';
import {
  LANDING_PAGE_CONTENT,
  LANDING_VIDEO_ID,
  WAITLIST_UTM_SOURCE
} from '../../constants/common';
import styles from './styles';
import LandingFaqContent from './LandingFaqContent';
import { FormattedMessage } from 'react-intl';
import analytics from '../../utils/analytics';
import { PAGES } from '../../constants/analytics';
import MobileBanner from '../../components/MobileBanner';
import { logger } from '../../utils/logger';
import WaitlistFeaturedSection from '../WaitlistFeaturedSection/index.tsx';

const Landing = ({ classes }) => {
  const {
    contentBody,
    gridContainer,
    headVideoWrapper,
    qsContainer,
    qsHead,
    qsWrapper,
    videoWrapper,
    waitlistContainer
  } = classes;

  const { push } = useHistory();
  const { width } = useWindowSize();

  const heroVideo = LANDING_VIDEO_ID.hero;
  const firstSquare = LANDING_VIDEO_ID.howCS;
  const secondSquare = LANDING_VIDEO_ID.whatCS;

  useEffect(() => {
    analytics.trackPageView(PAGES.LANDING);
  }, []);

  const handleLogin = () => {
    analytics.trackClick(PAGES.LANDING, 'LOGIN');
    logger.clickStream('Landing: loginClick');
    push('/login');
  };

  return (
    <>
      <title>Walmart - Customer Spark</title>
      <header>
        <nav className={classes.nav}>
          <div className={classes.landingWrapper}>
            <Logo type="landing" />
            <Grid className={classes.btnContainer}>
              <Link to={`/waitlist?utm_source=${WAITLIST_UTM_SOURCE.homepage}`}>
                <Button
                  UNSAFE_className={`${classes.headerBtn} ${classes.joinWailistBtn}`}
                  data-testid="header-join-waitlist-btn"
                  variant="secondary"
                >
                  <FormattedMessage
                    id="JOIN_WAITLIST"
                    defaultMessage="Join our waitlist"
                  />
                </Button>
              </Link>
              <Button
                id="login"
                className={classes.headerBtn}
                variant="primary"
                data-testid="header-login-btn"
                onClick={handleLogin}
              >
                <FormattedMessage id="LOG_IN" defaultMessage="Log In" />
              </Button>
            </Grid>
          </div>
        </nav>
      </header>
      <Grid className={gridContainer}>
        <Grid item container className={qsContainer}>
          <MobileBanner />
        </Grid>
        <Grid item container className={qsContainer}>
          <Grid item className={qsWrapper}>
            <Typography className={qsHead}>
              <FormattedMessage
                id="LANDING_PAGE_CONTENT_Q1"
                defaultMessage={LANDING_PAGE_CONTENT.Q1}
              />
            </Typography>
            <Typography className={contentBody}>
              <FormattedMessage
                id="LANDING_PAGE_CONTENT_A1"
                defaultMessage={LANDING_PAGE_CONTENT.A1}
              />
            </Typography>
          </Grid>
          <Grid item className={videoWrapper}>
            <VideoPlayer
              autoPlay={false}
              config={{ page: PAGES.LANDING }}
              index={1}
              videoId={firstSquare}
            />
          </Grid>
        </Grid>
        <Grid item container className={qsContainer}>
          {width > 600 && (
            <Grid item className={videoWrapper}>
              <VideoPlayer
                autoPlay={false}
                config={{ page: PAGES.LANDING }}
                index={2}
                videoId={secondSquare}
              />
            </Grid>
          )}
          <Grid item className={qsWrapper}>
            <Typography className={qsHead}>
              <FormattedMessage
                id="LANDING_PAGE_CONTENT_Q2"
                defaultMessage={LANDING_PAGE_CONTENT.Q2}
              />
            </Typography>
            <Typography className={contentBody}>
              <FormattedMessage
                id="LANDING_PAGE_CONTENT_A2"
                defaultMessage={LANDING_PAGE_CONTENT.A2}
              />
            </Typography>
          </Grid>
          {width <= 600 && (
            <Grid item className={videoWrapper}>
              <VideoPlayer
                autoPlay={false}
                config={{ page: PAGES.LANDING }}
                index={2}
                videoId={secondSquare}
              />
            </Grid>
          )}
        </Grid>
        <Grid className={waitlistContainer}>
          <WaitlistFeaturedSection utmSource={WAITLIST_UTM_SOURCE.homepage} />
        </Grid>
        <Grid item className={headVideoWrapper}>
          <VideoPlayer
            autoPlay={true}
            config={{ page: PAGES.LANDING }}
            index={0}
            videoId={heroVideo}
          />
        </Grid>
        <Carousel />
        <LandingFaqContent />
        <Grid item>
          <HomeFooter type="landing" />
        </Grid>
      </Grid>
    </>
  );
};

Landing.defaultProps = {
  classes: {}
};

Landing.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(Landing);
